import React from "react"
import { Container } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"

const HeroSubpage = ({ intl, theme, content }) => (
    <div className={`hero-subpage ` + theme}>
        <div className={`clipped-area`}></div>
        <div className={`non-clipped-area`}>
            <Container>
                <h1>{content.title}</h1>
                {content.text &&
                <div className={`text`}>{content.text}</div>
                }
            </Container>
        </div>
    </div>
)

export default injectIntl(HeroSubpage)
